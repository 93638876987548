















































































































































import { Component, Watch, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

import InvestmentTransaction from '@/models/investment_transaction'
import Investment from '@/models/investment'

import Listbox from 'primevue/listbox'
import Button from 'primevue/button'
import SelectButton from 'primevue/selectbutton'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import Toolbar from 'primevue/toolbar'
import Panel from 'primevue/panel'
import AppInvestmentTransactionsTable from '@/components/AppInvestmentTransactionsTable'
import AppColorTag from '@/components/AppColorTag'

Vue.component('Listbox', Listbox)
Vue.component('Button', Button)
Vue.component('SelectButton', SelectButton)
Vue.component('Calendar', Calendar)
Vue.component('Dropdown', Dropdown)
Vue.component('Toolbar', Toolbar)
Vue.component('Panel', Panel)
Vue.component('AppInvestmentTransactionsTable', AppInvestmentTransactionsTable)
Vue.component('AppColorTag', AppColorTag)

dayjs.extend(quarterOfYear)

const Auth = namespace('auth')

interface TransactionDateShortcut {
  name: string;
  value: (string | null)[] | null;
}

@Component
export default class ContributionsAndDistributions extends Vue {
  @Auth.Getter currentPermissions!: string

  investments: Investment[] = []
  investmentTypes = [
    { text: 'All Investment Types', value: null },
    { text: 'Agriculture', value: 'agriculture' },
    { text: 'Equity', value: 'equity' },
    { text: 'Lending', value: 'lending' },
    { text: 'Real Estate', value: 'real_estate' }
  ]

  allInvestmentOption = new Investment({
    id: null,
    name: 'All Investments',
    active: 1
  })

  investmentParam: Investment[] = [this.allInvestmentOption]
  transactionDateParam: (string | null)[] | null = [
    null,
    dayjs().format('YYYY-MM-DD')
  ]

  totalContributions = 0
  totalDistributions = 0

  showCustomDateRangePicker = false
  showCustomDateUpToPicker = false
  showExitedInvestments = false

  transactionDateShortcuts: TransactionDateShortcut[] = [
    {
      name: 'Default',
      value: [
        null,
        dayjs().format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Month',
      value: [
        dayjs().startOf('month').format('YYYY-MM-DD'),
        dayjs().endOf('month').format('YYYY-MM-DD')
      ]
    }, {
      name: 'Last Month',
      value: [
        dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Quarter',
      value: [
        dayjs().startOf('quarter').format('YYYY-MM-DD'),
        dayjs().endOf('quarter').format('YYYY-MM-DD')
      ]
    }, {
      name: 'Last Quarter',
      value: [
        dayjs().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD')
      ]
    }, {
      name: 'This Year',
      value: [
        dayjs().startOf('year').format('YYYY-MM-DD'),
        dayjs().endOf('year').format('YYYY-MM-DD')
      ]
    }, {
      name: 'Last Year',
      value: [
        dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
      ]
    }, {
      name: 'All Dates Up To...',
      value: [null]
    }, {
      name: 'Select Dates...',
      value: []
    }
  ]

  hiddenInvestmentTransactionColumns = [
    'interestRate',
    'interestAmount'
  ]

  enableUpdateInvestmentTransaction = false

  get investmentTypeOptions () {
    const types = this.investments.map(item => { return item.investmentType })
    return this.investmentTypes.filter(item => {
      return types.includes(item.value as string)
    })
  }

  get filteredInvestments () {
    return this.investments.filter(item => {
      return this.showExitedInvestments || (!this.showExitedInvestments && item.active)
    })
  }

  get parsedInvestmentParam () {
    if (this.investmentParam.length === 1 &&
      !this.investmentParam[0].id) return []
    return this.investmentParam
  }

  get formattedTransactionDateParam () {
    if (!this.transactionDateParam) return null
    const endDate = dayjs(this.transactionDateParam[1] as string).format('YYYY-MM-DD')
    const formattedTransactionDateParam: { lte: string | null; gte: string | null } = {
      lte: endDate,
      gte: null
    }
    if (this.transactionDateParam[0] !== null) {
      const startDate = dayjs(this.transactionDateParam[0] as string).format('YYYY-MM-DD')
      formattedTransactionDateParam.gte = startDate
    }
    return formattedTransactionDateParam
  }

  @Watch('transactionDateParam')
  onTransactionDateParamChanged () {
    if (!this.transactionDateParam || !this.transactionDateParam[1]) return
    this.getSummaryStatistics()
  }

  @Watch('investmentParam')
  onInvestmentParamChanged () {
    this.getSummaryStatistics()
  }

  onChangeSelectedInvestments (event: any) {
    const justAddedOption = event.value[event.value.length - 1]
    if (event.value.length === 0 || justAddedOption.id === null) {
      // all investment option selected
      this.investmentParam = [this.allInvestmentOption]
    } else {
      // some other investment selected
      this.investmentParam = this.investmentParam.filter(item => {
        return item && Number(item.id) > 0
      })
    }
  }

  mounted () {
    this.getCurrentPermissions()
    this.getInvestments()
    this.getSummaryStatistics()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableUpdateInvestmentTransaction = permissions.includes('update_investment_transaction')
  }

  async getSummaryStatistics () {
    const response = await InvestmentTransaction
      .where({
        investmentId: this.investmentParam.length > 0 ? this.investmentParam.map(item => { return item.id }) : null,
        transactionDate: this.formattedTransactionDateParam
      })
      .per(0)
      .stats({
        contributionAmount: 'sum',
        distributionAmount: 'sum'
      })
      .all()
    this.totalContributions = response.meta.stats.contribution_amount.sum
    this.totalDistributions = response.meta.stats.distribution_amount.sum
  }

  async getInvestments () {
    let investments: any[] = (await Investment
      .order({ active: 'desc' })
      .order('name')
      .per(1000)
      .all()
    ).data

    investments = [
      this.allInvestmentOption
    ].concat(investments.map(item => { return item }))

    investments = this.insertActiveExitedSeparator(investments)
    this.investments = investments
  }

  resetTransactionDateShortcutOptions () {
    this.transactionDateShortcuts.filter(item => {
      return item.name === 'All Dates Up To...'
    })[0].value = [null]
  }

  removeInvestmentParam (investmentId: string) {
    let investmentParam: Investment[] = []
    investmentParam = this.investmentParam.filter(investment => {
      return investment.id !== investmentId
    })
    if (investmentParam.length === 0) {
      investmentParam = [this.allInvestmentOption]
    }
    this.investmentParam = investmentParam
  }

  onChangeSelectedDates () {
    this.resetTransactionDateShortcutOptions()

    const val = this.transactionDateParam

    if (!val || val.length === 0) {
      this.showCustomDateRangePicker = true
      Vue.nextTick(function () {
        const customRangePicker = document.getElementById('custom_range_picker')
        if (customRangePicker) {
          customRangePicker.focus()
        }
      })
    } else if (val.length === 1) {
      this.showCustomDateUpToPicker = true
      Vue.nextTick(function () {
        const customUpToPicker = document.getElementById('custom_up_to_picker')
        if (customUpToPicker) {
          customUpToPicker.focus()
        }
      })
    } else if (val.length === 2 && val[1] !== null) {
      this.showCustomDateRangePicker = false
      this.showCustomDateUpToPicker = false
    }
  }

  insertActiveExitedSeparator (investments: any[]) {
    let idx = -1
    for (let i = 0; i < investments.length; i++) {
      const investment = investments[i]
      if (!investment.active) {
        idx = i
        break
      }
    }
    if (idx >= 0) {
      investments.splice(idx, 0, new Investment({
        name: 'Exited Investments',
        active: true
      }))
    }
    return investments
  }

  toggleExitedInvestments () {
    this.showExitedInvestments = !this.showExitedInvestments
  }
}
